import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Box, Text } from "@chakra-ui/react";
import { MonthlyGraphCount } from "views/admin/request/admin_request";
import LoadingComp from "components/LodingComp/LoadingComp";

const MonthlyChart = () => {
  // State variables to manage chart data and loading state
  const [monthlyChartData, setMonthlyChartData] = useState({});
  const [loading, setLoading] = useState(true); // To track loading status
  const [labels, setLabels] = useState([]); // Labels for the x-axis (months)
  const [campCounts, setCampCounts] = useState([]); // Data for camp counts
  const [testCounts, setTestCounts] = useState([]); // Data for test counts

  // Fetch chart data from API
  const getMonthlyChartData = async () => {
    try {
      // Fetch data using the MonthlyGraphCount API function
      let { data } = await MonthlyGraphCount();
      if (data.status === "success") {
        // Extract and set data for labels, camp counts, and test counts
        setLabels(data.data.map((item) => item.month));
        setCampCounts(data.data.map((item) => item.camp_count));
        setTestCounts(data.data.map((item) => item.test_count));
      } else {
        // Handle error case (e.g., show an error message or navigate to an error page)
      }
    } catch (err) {
      // Handle network or API errors
      console.error(err);
    } finally {
      // Set loading to false after data fetching is complete
      setLoading(false);
    }
  };

  // Run the API call when the component mounts
  useEffect(() => {
    getMonthlyChartData();
  }, []);

  // Configuration options for the chart
  const chartOptions = {
    chart: {
      type: "bar", // Type of chart: bar
      height: 500, // Chart height
      animations: {
        enabled: true,
        easing: "easeinout", // Animation easing
        speed: 1000, // Animation speed
      },
      toolbar: {
        show: false, // Disable the toolbar
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // Vertical bar chart
        columnWidth: "55%", // Width of columns
        endingShape: "rounded", // Rounded column edges
      },
    },
    fontFamily: "Poppins", // Set font family
    dataLabels: {
      enabled: false, // Disable data labels on bars
    },
    stroke: {
      show: true, // Enable stroke around bars
      width: 2, // Stroke width
      colors: ["transparent"], // Stroke color
    },
    xaxis: {
      categories: labels, // Use labels for x-axis categories
      title: {
        text: "Months", // Title for x-axis
      },
    },
    yaxis: {
      title: {
        text: "Count", // Title for y-axis
      },
      labels: {
        formatter: (value) => value.toLocaleString(), // Format y-axis labels
      },
    },
    tooltip: {
      y: {
        formatter: (value) => value.toLocaleString(), // Format tooltip values
      },
    },
    legend: {
      position: "top", // Position legend at the top
      horizontalAlign: "left", // Align legend to the left
      floating: true, // Allow the legend to float
      offsetY: -10, // Vertical offset for the legend
      offsetX: 20, // Horizontal offset for the legend
      onItemClick: {
        toggleDataSeries: false, // Disable toggling series visibility on click
      },
      onItemHover: {
        highlightDataSeries: false, // Disable highlighting series on hover
      },
    },
  };

  // Data series for the chart
  const chartSeries = [
    {
      name: "Camp Count", // Series name
      data: campCounts, // Data for camp counts
    },
    {
      name: "Test Count", // Series name
      data: testCounts, // Data for test counts
    },
  ];

  return (
    <Box p="20px" bg="white" mt="40px" borderRadius="md" shadow="md">
      {/* Chart title */}
      <Text mb="10px" fontWeight="bold" fontSize="20px" color="gray.600">
        Camp & Test Summary <small>(Last 3 months)</small>
      </Text>

      {/* Show loading component while data is being fetched */}
      {loading ? (
        <LoadingComp />
      ) : (
        // Render the chart once data is loaded
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={400}
        />
      )}
    </Box>
  );
};

export default MonthlyChart;
