import React from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    Spinner,
} from "@chakra-ui/react";


export const AlertBox = ({ setIsOpen, isOpen, msg, header, action, state_loading }) => {
    const cancelRef = React.useRef()


    return (
        <>
            {/* <Button onClick={()=>setIsOpen(true)}>Discard</Button> */}
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>{header}</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {msg.message}
                    </AlertDialogBody>
                    <AlertDialogFooter>

                        <Button ref={cancelRef} onClick={() => setIsOpen(!isOpen)}>
                            Cancel
                        </Button>
                        <Button disabled={state_loading} colorScheme='teal' onClick={() => action(msg.element)} ml={3}>
                            {state_loading ? <Spinner /> : 'Yes'}
                        </Button>

                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}

export default AlertBox