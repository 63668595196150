import React, { useState, useContext, useRef } from 'react';
import {
    Box, Image, Flex, Input, FormLabel, InputGroup, InputRightElement, Icon, Button, FormControl, Stack, useToast, Heading, Text
} from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom'
import logo from '../../../assets/img/logo.png'
import { AuthContext } from "layouts/auth/Authantication";
import { Link } from 'react-router-dom';

let initialvalue = {
    "username": "",
    "password": "",
};

const Signin = () => {

    const [auth, setAuth] = useState(initialvalue)
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const { loginAuth } = useContext(AuthContext)
    const Navigate = useNavigate()
    const EmailInput = useRef(null);
    const toast = useToast();


    const handleClick = () => setShow(!show);

    const handelinput = (e) => {
        let { value, name } = e.target;
        setAuth({ ...auth, [name]: value })
    }

    const handelAuth = async (event) => {
        event.preventDefault()
        setLoading(true)
        try {
            await loginAuth(auth)
                .then((res) => {
                    setLoading(false)

                    if (res === 'success') {

                        Navigate('/admin/dashboard')
                    } else {
                        if (res.response.data.message === 'Unauthorized') {
                            toast({
                                title: "Unable to log in. Your credentials don't match our records.",
                                position: 'top',
                                status: 'error',
                                isClosable: true,
                            })
                        } else {
                            toast({
                                title: res.response.data.message,
                                position: 'top',
                                status: 'error',
                                isClosable: true,
                            })
                        }
                    }
                })
        } catch (err) {
            setLoading(false)
            toast({
                title: 'Something wents wrong',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        }

    }
    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bgGradient='linear(to-r, rgb(19,171,194), rgb(17,198,163))'
        >
            <Flex w='100%' justifyContent='center' alignItems='center' direction='column'>



                <Box boxShadow='rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px' w={{ base: '90%', md: '50%', xl: '30%' }} bg='#FFFF' p='20px' pt='50px' pb='50px' h='fit-content' color='gray' fontSize='s' mt={{ base: '20px', md: '0', lg: '30px' }} borderRadius='10px'>
                    <Stack spacing='20px' >
                        <Box m='auto' w='200px' marginBottom={{ base: '20px', md: '50px' }}>
                            <Image w='100%' src={logo} alt='logo' />
                        </Box>
                        <form onSubmit={handelAuth}>
                            <FormControl isRequired>

                                {/*input Username */}
                                <FormLabel as='samp'>Username</FormLabel>
                                <InputGroup size='md' mb='20px'>
                                    Username
                                    <Input
                                        isRequired={true}
                                        variant='auth'
                                        h='50px'
                                        name='username'
                                        bg='#F1F6F9'
                                        placeholder='Enter your username'
                                        borderRadius='3px'
                                        border='2px solid white'
                                        color='black'
                                        onChange={(e) => handelinput(e)}
                                        ref={EmailInput}
                                    />
                                </InputGroup>

                                {/*input password */}
                                <FormLabel as='samp'>Password</FormLabel>
                                <InputGroup size='md'>
                                    <Input
                                        isRequired={true}
                                        type={show ? "text" : "password"}
                                        variant='auth'
                                        name='password'
                                        bg='#F1F6F9'
                                        color='black'
                                        h='50px'
                                        placeholder='Enter your password'
                                        border='2px solid white' borderRadius='3px'
                                        onChange={(e) => handelinput(e)}
                                    />
                                    <InputRightElement display='flex' alignItems='center' m='auto'>
                                        <Icon
                                            // _hover={{ cursor: "pointer" }}
                                            as={!show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                            onClick={handleClick}
                                        />
                                    </InputRightElement>

                                </InputGroup>

                                <Text textAlign='end' color='blue' fontSize='12px'> <Link color='blue.500' to='/auth/sign-in/forgetpw'>Forget Password? </Link></Text>

                            </FormControl>

                            {/* submit */}
                            <Button
                                h='50px'
                                mt='20px'
                                w='100%' borderRadius='5px'
                                fontWeight='regular'
                                border='2px solid white'
                                type='submit'
                                isLoading={loading}
                                colorScheme='teal'
                            >
                                {loading ? 'Loading ...' : 'Sign In'}
                            </Button>
                        </form>
                    </Stack>

                </Box>
            </Flex>
        </Flex>
    )
}

export default Signin