import React, { useEffect, useState } from "react";
// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import ReactApexChart from "react-apexcharts";
// import Datepicker from './Componunts/Datepicker';
import Datepicker from "./Component/Datepicker";
import startOfQuarter from 'date-fns/startOfQuarter';
import { startOfMonth } from 'date-fns';
import { format } from 'date-fns';
// import { lowest_regionwise_graphs_piechart } from "views/admin/request/admin_request";
import LoadingComp from "components/LodingComp/LoadingComp";



export default function Commongraph({fetchfunc, header}) {
    const [campData, setCampData] = useState([]);
    const [stateCodes, setStateCods] = useState([]);
    const [loading, setLoading] = useState([]);

    const [date, setDate] = useState([
        {
          startDate: startOfMonth(new Date()),
          endDate: new Date(),
          key: 'selection'
        }
      ]);
    let [to_date, setTo_date] = useState(format(new Date(), 'dd-MM-yyyy').toString());
    let [start_date, setStart_date] = useState(format(startOfMonth(new Date()), 'dd-MM-yyyy').toString());

    const handelDatefilter = () => {
      let enddivas = format(date[0].endDate, 'dd-MM-yyyy').toString();
      let startdivas = format(date[0].startDate, 'dd-MM-yyyy').toString();
      setStart_date(startdivas);
      setTo_date(enddivas)
    };
    
    const handeldate = (val) => {
      setDate(val)
    };


  
    const getData = async () => {
      try {
        setLoading(true)
        let { data } = await fetchfunc(start_date, to_date);
        // let { data } = await fetchfunc();

        let camps = data.data.map(ele => ele.camps);
        let state_code = data.data.map(ele => toTitleCase(ele.region? ele.region : ele.hq));
        // console.log({ camps, state_code })
        setCampData(camps);
        setStateCods(state_code);
        setLoading(false);
      } catch (err) {
        console.log({ Error: err })
      }
    }

    function toTitleCase(str) {
        if (!str || typeof str !== 'string' || str.trim() === '') {
            return '-';
        }
        return str.replace(
          /\w\S*/g,
          (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        );
    }


    const getColor = (value, min, max) => {
      const percentage = (value - min) / (max - min); // Normalize between 0 and 1
      const red = Math.round(255 * (1 - percentage)); // More red for lower values
      const green = Math.round(255 * percentage); // More green for higher values
      return `rgb(${red}, ${green}, 50)`; // RGB format
    };
  
    let minValue = Math.min(...campData);
    let maxValue = Math.max(...campData);

    let pieChartOptions = {
      colors: [
        "#68D391", // green.200
        "#4FD1C5", // teal.200 (Magenta)
        "#F6E05E", // yellow.200 (HotPink)
        "#FF7F50", // Coral 
        "#63B3ED", // blue.200 (OrangeRed)
        "#F687B3", // pink.200 (Cyan)
        "#F56565", // red.200 (Lime)
        "#D4D4D4",
        "#00FF7F", // SpringGreen
        "#FFA500", // Orange
      ],
      chart: {
        type: "bar",
        height: 300,
        width: 100,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          distributed: true,
          barHeight: "80%",
          dataLabels:{
            position:"start"
          }
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000"],
          fontSize: "12px",
          fontWeight: "bold",
        },
        offsetX: -40,
        textAnchor: "start", // Align text to the left
        formatter: function (val) {
          return val;
        },
      },                     
      xaxis: {
        categories: stateCodes,
      },
      fill: {
        colors: [
          "#68D391", // green.200
          "#4FD1C5", // teal.200 (Magenta)
          "#F6E05E", // yellow.200 (HotPink)
          "#FF7F50", // Coral 
          "#63B3ED", // blue.200 (OrangeRed)
          "#F687B3", // pink.200 (Cyan)
          "#F56565", // red.200 (Lime)
          "#D4D4D4",
          "#00FF7F", // SpringGreen
          "#FFA500", // Orange
        ],
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        y: {
          title: {
            formatter: () => "Count:",
          },
        },
      },
    };


    useEffect(() => {
      getData()
      // console.log({ campData, stateCodes })
    }, [start_date, to_date])



  return (
    <Box p="20px" bg="white" mt="10px"  borderRadius="md"  shadow='md' >
      <Flex w='100%' overflow='auto' justifyContent='space-between' alignItems={{ base: "stretch", md: "center" }} flexDirection={{ base: "column", md: "row" }}>
        <Text mb="5px" fontWeight='bold' fontSize='20px' color='gray.600'>{header}</Text>
        <Datepicker
          date={date}
          start_date={start_date}
          to_date={to_date}
          handeldate={handeldate}
          handelDatefilter={handelDatefilter}
        />
      </Flex>

        {
            loading ? <LoadingComp /> :
            <ReactApexChart options={pieChartOptions} series={[{ data: campData }]} type="bar" height={300}/>
        }
    </Box>
  )
}
