import axios from "axios";


const API_URL = process.env.REACT_APP_API_URL;

//All Authantication API calls -----------------------------------------------------------


export const Login = async (obj) => {
    try {
        let { data } = await axios.post(`${API_URL}/login`, obj)
        let token = data.data.token;
        let user = data.data.user;
        localStorage.setItem('token', token);
        localStorage.removeItem('user')
        localStorage.setItem('user', JSON.stringify(user));
        return data
    } catch (err) {
        return err
    }
};


export const ForgetPW = async (str) => {
    try {
        let { data } = await axios.post(`${API_URL}/forgetPassword`, {
            "email": str
        });
        return data
    } catch (err) {
        return err
    }
}


export const ChangeUserPW = async (token, email, pw) => {

    try {
        let data = JSON.stringify({
            "password": pw,
            "password_confirmation": pw,
            "email": email,
            "token": token
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_URL}/reset_password`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: data
        };
        
        let res = await axios.request(config);
        return res

    } catch (err) {
        return err
    }


}