import React, { useEffect, useState, useRef } from 'react';
import {
    Flex, Text, Button, Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem, Spacer, Icon
} from '@chakra-ui/react';
import { useDebounce } from 'use-debounce';
import Card from 'components/card/Card';
import DataTableFrame from 'components/datatable/DataTableFrame';
import Datatablepagination from 'components/pagination/Datatablepagination';
import Datepicker from './Componunts/Datepicker';
import { format } from 'date-fns';
import startOfQuarter from 'date-fns/startOfQuarter';
import FilterScale from 'components/filterComponunts/Filter_Scale';
import Divisionfilter from 'components/filterComponunts/Divisionfilter';
import Search from 'components/Search/Search';
import LoadingComp from 'components/LodingComp/LoadingComp';
import { TiExport } from "react-icons/ti";
import AlertMsg from './AlertMsg';

const ReportTable = ({ header, column, fetchfun, exportFunction, exportConsent, countlabel, sortablecolm, showscale = 'true' }) => {

    let { divisions } = JSON.parse(localStorage.getItem('user')) || [];
    let [loading, setLoading] = useState(true);
    let [exportLoad, setExportLoad] = useState(false);
    let [data, setData] = useState([]);
    let [page, setPage] = useState(1);
    let [perpage, setPerpage] = useState(1);
    let [totalData, setTotadata] = useState(0);
    let [filterText, setFilterText] = useState('');
    let [divi, setDivi] = useState(divisions[0].division_id);
    let [scale, setScale] = useState('all');
    let [sortLoading, setSortloading] = useState(false);
    const isInitialRender = useRef(true);
    const [isOpen, setIsOpen] = useState(false);
    let [msg, setMsg] = useState({});

    const [sortColumn, setSortColumn] = useState(sortablecolm); // Default sort column
    const [sortDirection, setSortDirection] = useState('asc'); // Default sort direction

    const [date, setDate] = useState([
        {
            startDate: startOfQuarter(new Date()),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    let [to_date, setTo_date] = useState(format(new Date(), 'dd-MM-yyyy').toString());
    let [start_date, setStart_date] = useState(format(startOfQuarter(new Date()), 'dd-MM-yyyy').toString());

    const [debouncedFilterText] = useDebounce(filterText, 500); // Debounce filterText by 500ms

    const handelDatefilter = () => {
        let enddivas = format(date[0].endDate, 'dd-MM-yyyy').toString();
        let startdivas = format(date[0].startDate, 'dd-MM-yyyy').toString();
        setStart_date(startdivas);
        setTo_date(enddivas)
    };

    // function to add date filter
    const handeldate = (val) => {
        setDate(val)
    };

    // function for set search filter
    const handelSearch = (val) => {
        setFilterText(val)
    };

    // function to set new page number
    const handelpagination = (val) => {
        setPage(page => page + val)
    };

    // function to set scale filter
    const handelScale = (val) => {
        if (val === scale) {
            return
        }
        setScale(val)
    };

    // function to set division filter
    const handeldivision = (val) => {
        if (val === divi) {
            return
        }
        setDivi(val)
    };

    // function to handel pagination
    const handelpagechange = (val) => {
        if (val === page || loading) {
            return
        }
        setPage(val)
    };

    // export report in excel function
    const handelExport = async (exportType) => {
        try {
            setExportLoad(true)
            let res = await exportType(divi, start_date, to_date, scale);
            setExportLoad(false)
            if (res.message) {
                setMsg(res);
                setIsOpen(true);
            }

        } catch (err) {
            setExportLoad(false)
            console.log(err);
        }
    };

    // sort function
    const handleSort = (column, sortDirection) => {
        setSortColumn(column.sortField);
        setSortDirection(sortDirection);
    };

    // fetch data 
    const getcampreport = async (type) => {

        if (scale === '') {
            return
        }
        try {
            if (type !== 'sort') {
                setLoading(true)
            }
            let data = await fetchfun(divi, start_date, to_date, page, debouncedFilterText, scale, sortColumn, sortDirection);
            if (data.status === 'success') {
                // console.log(data.data.data);
                setPage(data.data.current_page);
                setPerpage(data.data.per_page);
                setData(data.data.data);
                setTotadata(data.data.total);
                setLoading(false);
            } else {
                // Navigate('/auth/sign-in');
            }
        } catch (err) {
            // Navigate('/auth/sign-in');
        }
    };


    useEffect(() => {
        getcampreport('regular');
    }, [divi, start_date, to_date, page, debouncedFilterText, scale]);
    // every change in dependancy array trigger the function 

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            setSortloading(true);
            getcampreport('sort');
            setSortloading(false);
        }
    }, [sortDirection]);
    // sort handaling 

    return (
        <Card>
            <Text fontSize='xl' pl='6px' mb='10px'>{header}</Text>
            <Flex gap='10px' w='100%' overflow='auto' alignItems='center' >
                <Divisionfilter
                    Data={divisions}
                    name="Division Name"
                    All={false}
                    handelSelect={handeldivision} />
                {!showscale
                    ? null :
                    <FilterScale divi={divi} handelScale={handelScale} scale={scale} header={header} />
                }

                <Datepicker
                    date={date}
                    start_date={start_date}
                    to_date={to_date}
                    handeldate={handeldate}
                    handelDatefilter={handelDatefilter} />

                <Spacer />

                <Search filterText={filterText} handelSearch={handelSearch} />
                {/* Export */}
                <Box>
                    {exportConsent ? (
                        <Menu>
                     
                            <MenuButton as={Button} isLoading={exportLoad} rightIcon={<TiExport />} colorScheme='teal' borderRadius='3px'>
                                Export
                            </MenuButton>
                            <MenuList bg='teal' p='5px'>
                               {/* export report Without Consent */}
                                <MenuItem rightIcon={<TiExport fontSize='20px' />} onClick={() => handelExport(exportFunction)}>
                                    <Icon as={TiExport} mr='5px' />
                                    <Text as='b'>Export</Text>
                                </MenuItem>
                                {/* export report with consent */}
                                <MenuItem onClick={() => handelExport(exportConsent)}>
                                    <Text as='b'>Export With Consent</Text>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    ) : (
                        <Button isLoading={exportLoad} rightIcon={<TiExport />} colorScheme='teal' borderRadius='3px' onClick={() => handelExport(exportFunction)}>Export</Button>
                    )}
                </Box>
            </Flex>
            {/* Data table */}
            <Box>
            {/* sending props to data table */}
                {loading ? <LoadingComp /> :
                    <DataTableFrame
                        data={data}
                        column={column}
                        keyvalue='id'
                        onSort={handleSort}
                        sortServer
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                }
            </Box>
            {/* Pagination */}
            <Datatablepagination
                page={page}
                totalData={totalData}
                perpage={perpage}
                handelpagechange={handelpagechange}
                handelpagination={handelpagination}
                Loading={loading}
                countlabel={countlabel}
            />
            <AlertMsg header={'Export Documents'} isOpen={isOpen} setIsOpen={setIsOpen} msg={msg} />
        </Card>
    )
}

export default ReportTable;
