import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import BreadCrumb from 'components/navbar/Breadcrumb';
import ReportTable from '../../reports/reporttable_componunt/GeneralDataTable';
import { fetch_camp_not_executed_report, ExportExcel_Camp_Plan_NotExecuted } from '../../reports/request';


function toTitleCase(str) {
  if (!str || typeof str !== 'string' || str.trim() === '') {
      return '-';
  }
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

const CampNotExecuted = () => {

// column for data table
  const column = [
    // {
    //     name: <Text as='b' textAlign='left'>ID</Text>,
    //     selector: row => row?.id || '-',
    //     sortable: true,
    //     sortField: 'id',
    //     minWidth: '120px',
    // },
    {
        name: <Text as='b'  textAlign='left'>Doctor Name</Text>,
        selector: row => toTitleCase(row?.doctor_name) || '-',
        sortable: true,
        sortField: 'doctor_name',
        minWidth: '120px',
    },
    {
        name: <Text as='b'  textAlign='left'>Doctor Code</Text>,
        selector: row => row?.doctor_code || '-',
        sortable: true,
        sortField: 'doctor_code',
        minWidth: '100px',
    },
    {
      name: <Text as='b'  textAlign='left'>Plan Date</Text>,
      selector: row => row?.plan_date_format || '-',
      sortable: true,
      sortField: 'plan_date_format',
      minWidth: '120px',
  },
    {
        name: <Text as='b'  textAlign='left'>Doctor Speciality</Text>,
        selector: row => toTitleCase(row?.speciality) || '-',
        sortable: true,
        sortField: 'speciality',
        minWidth: '150px',
    },
    {
        name: <Text as='b'  textAlign='left'>Employee Name</Text>,
        selector: row => toTitleCase(row?.name) || '-',
        sortable: true,
        sortField: 'name',
        minWidth: '80px',
    },
    {
        name: <Text as='b'  textAlign='left'>Employee Code</Text>,
        selector: row => row?.emp_code || '-',
        sortable: true,
        sortField: 'emp_code',
        minWidth: '150px',
    },
    {
        name: <Text as='b'  textAlign='left'>Prescriber Type</Text>,
        selector: row => toTitleCase(row?.prescriber_type) || '-',
        sortable: true,
        sortField: 'prescriber_type',
        minWidth: '180px',
    },
    {
        name: <Text as='b'  textAlign='left'>Status</Text>,
        selector: row => row?.status || '-',
        sortable: true,
        sortField: 'status',
    }
];


  return (
    <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>
    <Box display={{ base: 'block', md: 'none' }} p='5px'>
        <BreadCrumb />
    </Box>

    {/* sending props to data table */}
        <ReportTable
        header={'Camp Not Executed Report'}
        column={column}
        fetchfun={fetch_camp_not_executed_report} //fetch function - props
        exportFunction={ExportExcel_Camp_Plan_NotExecuted} // export function - props
        // exportConsent={CampReportExportConsent}
        countlabel={'Camps'}
        sortablecolm='id'
        showscale={false}
        />
   

</Box>
  )
}

export default CampNotExecuted