// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import ReactApexChart from "react-apexcharts";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
// import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React, { useEffect, useState } from "react";
import { top_regionwise_graphs_piechart } from "views/admin/request/admin_request";
import LoadingComp from "components/LodingComp/LoadingComp";

// Function to convert string to title case (first letter capitalized)
function toTitleCase(str) {
  if (!str || typeof str !== 'string' || str.trim() === '') {
    return '-';
  }
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export default function Conversion() {
  // States for holding data and loading status
  const [campData, setCampData] = useState([]); // Store camp data
  const [stateCodes, setStateCods] = useState([]); // Store state codes
  const [loading, setLoading] = useState(false); // Loading indicator

  // Function to fetch data for the pie chart (monthly region-wise data)
  const getData = async () => {
    try {
      setLoading(true); // Set loading to true before fetching data
      let { data } = await top_regionwise_graphs_piechart(); // Fetch data
      let camps = data.data.map(ele => ele.camps); // Extract camps data
      let state_code = data.data.map(ele => toTitleCase(ele.state_code)); // Format state codes
      // Update the state with fetched data
      setCampData(camps);
      setStateCods(state_code);
      setLoading(false); // Set loading to false after data is fetched
    } catch (err) {
      console.log({ Error: err }); // Log any error during data fetch
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    getData(); // Call getData to fetch data
  }, []);

  // Chakra Color Mode (for dynamic theme)
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  // Pie chart configuration options
  let pieChartOptions = {
    labels: stateCodes, // Labels for the pie chart (state codes)
    colors: [
      "#68D391", "#F56565", "#4FD1C5", "#F687B3", "#63B3ED", "#F6E05E", 
      "#D4D4D4", "#FF7F50", "#00FF7F", "#FFA500"
    ], // Color palette for the chart
    chart: {
      type: 'donut', // Donut chart style
      animations: {
        enabled: true, // Enable animations
        easing: 'easeinout', // Smooth animation easing
        speed: 1000, // Animation speed
        animateGradually: {
          enabled: true, // Enable gradual animation
          delay: 150, // Delay before animation starts
        },
        dynamicAnimation: {
          enabled: true, // Enable dynamic animation
          speed: 500, // Speed of dynamic animation
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "none", // No hover effect filter
        },
      },
    },
    legend: {
      show: true, // Display legend
      formatter: (seriesName, { seriesIndex }) => {
        // Format legend to display region and corresponding camp data
        return `${stateCodes[seriesIndex]} - ${campData[seriesIndex] || 0}`;
      },
      onItemClick: {
        toggleDataSeries: false, // Disable series toggle on legend click
      },
      onItemHover: {
        highlightDataSeries: false, // Disable series highlight on hover
      },
    },
    dataLabels: {
      enabled: true, // Enable data labels on chart
    },
    hover: { mode: null }, // Disable hover mode
    fill: {
      colors: [
        "#68D391", "#F56565", "#4FD1C5", "#F687B3", "#63B3ED", "#F6E05E", 
        "#D4D4D4", "#FF7F50", "#00FF7F", "#FFA500"
      ], // Fill colors for the chart
    },
    tooltip: {
      enabled: true, // Enable tooltip
      theme: "dark", // Dark theme for tooltip
    },
  };

  return (
    <Box p="20px" bg="white" mt="40px" borderRadius="md" shadow='md'>
      {/* Title of the chart */}
      <Text mb="5px" fontWeight='bold' fontSize='20px' color='gray.600'>
        Top 10 Regions <small>(Based on Camps of last 3 months)</small>
      </Text>
      {
        loading ? <LoadingComp /> : 
        <ReactApexChart options={pieChartOptions} series={campData} type="donut" />
      }
      {/* Show pie chart when data is ready, or show loading indicator */}
    </Box>
  );
}
