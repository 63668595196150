import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { fetch_Speciality_wise_reports } from '../request';
import ReportTable from '../reporttable_componunt/GeneralDataTable';

// Function to convert string to title case
function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
};


const SpecialityWiseReports = () => {

    // column for data table
    const column = [
        {
            name: <Text as='b'>Speciality</Text>,
            selector: row => toTitleCase(row?.doc_speciality) || '-',
            sortable: true,
            sortField: 'doc_speciality',
        },
        {
            name: <Text as='b'>Camps</Text>,
            selector: row => row?.camps || '-',
            sortable: true,
            sortField: 'camps',
        },
        {
            name: <Text as='b'>Doctors</Text>,
            selector: row => row?.doctors || '-',
            sortable: true,
            sortField: 'doctors',
        },
        {
            name: <Text as='b'>Patients</Text>,
            selector: row => row?.patients || '-',
            sortable: true,
            sortField: 'patients',
        }
    ];



    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>

            {/*sending props to data table   */}
            <ReportTable
                header={'Speciality wise Report'}
                column={column}
                fetchfun={fetch_Speciality_wise_reports} // fetch function
                // exportFunction={ExportExcel_ReginReport}
                // exportConsent={}
                countlabel={'Regions'}
                sortablecolm='doc_speciality'
                showscale={false}
            />
        </Box>
    )
}

export default SpecialityWiseReports