import React, { useState } from 'react';
import {
    Box, Image, Flex, Input,
    FormLabel, InputGroup,
    Button, FormControl,
    Stack, useToast
} from '@chakra-ui/react';
import { ForgetPW } from '../request'; // Import the function to handle the password reset request.
import { useNavigate } from "react-router-dom"; // Import navigation hook for routing.
import logo from '../../../../assets/img/logo.png'; // Import the logo image.

const Forgetpassword = () => {
    // State variables to manage input, loading state, and toast notifications.
    let [username, setUsername] = useState('');
    let toast = useToast(); // Chakra UI's toast for notifications.
    let Navigate = useNavigate(); // React Router's navigation hook.
    let [loading, setLoading] = useState(false); // State to track the loading status of the form submission.

    // Function to handle the password reset request.
    const handelAuth = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior.
        setLoading(true); // Set loading to true to show the spinner in the submit button.

        // Call the ForgetPW function with the provided username (email).
        await ForgetPW(username)
            .then(res => {
                setLoading(false); // Stop the loading spinner once the response is received.
                if (res.status === 'We have emailed your password reset link.') {
                    // If the response indicates success, show a success toast and navigate to the sign-in page.
                    toast({
                        title: res.status,
                        status: 'success',
                        isClosable: true,
                        position: 'top'
                    });
                    Navigate(`/auth/sign-in`);
                } else {
                    // If the response indicates failure, show an error toast.
                    toast({
                        title: 'Request failed.',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                }
            });
    };

    return (
        <Flex
            bgGradient='linear(to-l, #26B0B1, #248F8F)' // Background gradient.
            w='100%' pl={{ base: '0', md: '0', lg: '55px' }} // Padding for responsiveness.
            h='100vh' // Full-screen height.
            justifyContent='center' // Center content horizontally.
            alignItems='center' // Center content vertically.
            m='' gap='30px'
        >
            {/* Form container */}
            <Box
                w={{ base: '100%', md: '30%' }}
                bg='#FFFF'
                p='20px'
                pt='50px'
                h='fit-content'
                color='gray'
                fontSize='s'
                mt={{ base: '0', md: '0', lg: '30px' }}
                borderRadius='10px'
                boxShadow='md'
            >
                {/* Logo */}
                <Flex m='auto' direction='column' w='50%' mb='70px'>
                    <Image src={logo} alt='logo' />
                </Flex>

                {/* Form */}
                <Box pb='50px'>
                    <Stack spacing='32px'>
                        <form onSubmit={handelAuth}>
                            <FormControl isRequired>
                                <FormLabel as='samp'>Email</FormLabel>
                                <InputGroup size='md' mb='20px'>
                                    <Input
                                        isRequired={true}
                                        variant='auth' // Custom Chakra UI variant (ensure this is defined).
                                        h='50px'
                                        type='email' // Input type for email validation.
                                        name='username'
                                        bg='#F1F6F9'
                                        placeholder='Enter your EmailId'
                                        borderRadius='3px'
                                        border='2px solid white'
                                        color='black'
                                        onChange={(e) => setUsername(e.target.value)} // Update the username state on input change.
                                    />
                                </InputGroup>
                            </FormControl>

                            {/* Submit button */}
                            <Button
                                isLoading={loading} // Show spinner when loading.
                                h='50px'
                                colorScheme='teal'
                                w='100%'
                                borderRadius='5px'
                                fontWeight='bold'
                                border='2px solid white'
                                type='submit'
                            >
                                {loading ? 'loading ...' : 'Submit'} {/* Change button text based on loading state. */}
                            </Button>
                        </form>
                    </Stack>
                </Box>
            </Box>
        </Flex>
    );
};

export default Forgetpassword;
