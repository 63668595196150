import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Flex, Box, } from '@chakra-ui/react';
import Sidebar from 'components/sidebar/Sidebar';
import routes from '../../routes.js';
import Navbar from '../../components/navbar/NavbarAdmin.js'
import PrivateRoute from 'layouts/auth/PrivateRoute.jsx';
import SignIn from 'views/auth/signIn/Signin.jsx';
import DoctorList from 'views/admin/reports/doctor_list/index.jsx';
import NotFound from './ErrorPage.jsx';
import UserReports from 'views/admin/default';
import Profile from 'views/admin/profile';
import EmployeeList from 'views/admin/employee/index.jsx';
import TestReports from 'views/admin/reports/test_reports/index.jsx';
import Campreports from 'views/admin/reports/camp_reports/index.jsx';
import RegionwiseReports from 'views/admin/reports/regionwise_reports/RegionwiseReports.jsx';
import DeviceList from 'views/admin/marketplace/components/Devicelist.jsx';
import HqWiseReports from 'views/admin/reports/HQwise_reports';
import SpecialityWiseReports from 'views/admin/reports/Speciality_wiseData';
import Forgetpassword from 'views/auth/signIn/componunts/forgetpassword.jsx';


import DefaultReport from 'views/admin/reports/Defaulter_report/index.jsx';
import EmployeewiseReport from 'views/admin/reports/Employeewise_report/index.jsx';
import Doc_Report from 'views/admin/reports/Employeewise_report/Componunta/Doc_report.jsx';

import CampPlanReport from 'views/admin/camp_plan/Camp_Plan_report/index.jsx';
import CampExecutedreports from 'views/admin/camp_plan/Camp_Executed_reports/index.jsx';
import CampNotExecuted from 'views/admin/camp_plan/Camp_Not_Executed/index.js';
import CampNotPlannedReport from 'views/admin/camp_plan/Camp_Not_planned/index.jsx';
import Changepw from 'views/auth/signIn/componunts/changepw.jsx';
import AddEmployee from 'views/admin/employee/components/AddEmployee.jsx';

const AllRoutes = () => {

    let { pathname } = useLocation();
    let Location = pathname.split('/')


    return (

            <Flex w='100%'>
                {(Location.includes('admin') || Location.includes('report') || Location.includes('camp_plan')) && <Box
                    position='sticky'
                    top='0px'
                    h='fit-content'
                    zIndex='999'
                    w={{ base: '0',md:0, xl: '18%' }}
                >
                    <Sidebar routes={routes} />
                </Box>}

                <Box
                    w={{ base: '100%', md: '100%%', xl:'100%' }}
                    overflow='auto'
                    transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                    transitionDuration='.2s, .2s, .35s'
                    transitionProperty='top, bottom, width'
                    transitionTimingFunction='linear, linear, ease'
                    >
                    
                    {/* Navbar is here --------- */}
                    {(Location.includes('admin') || Location.includes('report') || Location.includes('camp_plan')) && (<Box><Navbar /></Box>)}
                    <Routes>
                    
                        <Route path={`/`} element={<Navigate to='/admin/dashboard' />} />

                        {/* redirect to Not-Defined page for unavailable route */}
                        <Route path={`/not-found`} element={<NotFound />} />
                        <Route path={`/*`} element={<Navigate to='/not-found' />} />

                        <Route path={`/admin/device-list`} element={<PrivateRoute><DeviceList /></PrivateRoute>} />
                        <Route path={`/admin/dashboard`} element={<PrivateRoute><UserReports /></PrivateRoute>} />
                        <Route path={`/admin/profile`} element={<PrivateRoute><Profile /></PrivateRoute>} />
                       
                        <Route path={`/admin/employee-list`} element={<PrivateRoute><EmployeeList /></PrivateRoute>} />
                        <Route path={`/admin/employee-list/add_employee`} element={<PrivateRoute><AddEmployee /></PrivateRoute>} />

                        <Route path={`/report/camp-reports`} element={<PrivateRoute><Campreports /></PrivateRoute>} />
                        <Route path={`/report/test-reports`} element={<PrivateRoute><TestReports /></PrivateRoute>} />
                        <Route path={`/report/regionwise-camp-reports`} element={<PrivateRoute><RegionwiseReports /></PrivateRoute>} />
                        <Route path={`/report/hqwise-camp-reports`} element={<PrivateRoute><HqWiseReports /></PrivateRoute>} />
                        <Route path={`/report/specialitywise-camp-reports`} element={<PrivateRoute><SpecialityWiseReports/></PrivateRoute>} />

                        <Route path={`/report/doctor-list`} element={<PrivateRoute><DoctorList /></PrivateRoute>} />
                        <Route path={`/report/defaulter_report`} element={<PrivateRoute><DefaultReport /></PrivateRoute>} />
                        <Route path={`/report/employeewise_report`} element={<PrivateRoute><EmployeewiseReport /></PrivateRoute>} />
                        <Route path={`/report/employeewise_report/:id`} element={<PrivateRoute><Doc_Report/></PrivateRoute>} />
 
                        <Route path={`/camp_plan/camp_plan_reports`} element={<PrivateRoute><CampPlanReport/></PrivateRoute>} />
                        <Route path={`/camp_plan/camp_plan_executed_report`} element={<PrivateRoute><CampExecutedreports/></PrivateRoute>} />
                        <Route path={`/camp_plan/camp_plan_not_executed_report`} element={<PrivateRoute><CampNotExecuted/></PrivateRoute>} />
                        <Route path={`/camp_plan/camp_not_plan`} element={<PrivateRoute><CampNotPlannedReport/></PrivateRoute>} />
                  
                        <Route path={`/auth/sign-in`} element={<SignIn />} />
                        <Route path={`/auth/sign-in/forgetpw`} element={<Forgetpassword/>} />
                        <Route path={`/reset-password/:id`} element={<Changepw/>} />

                    </Routes>
                  </Box>
            </Flex>
    )
}

export default AllRoutes