import React, { useState } from 'react';
import {
    Box, Image, Flex, Input,
    FormLabel, InputGroup,
    Button, FormControl,
    Stack, useToast, InputRightElement, Icon
} from '@chakra-ui/react';

import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../../../assets/img/logo.png';
import { ChangeUserPW } from '../request';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

const Changepw = () => {
    // State for loading, password, and confirm password inputs
    let [loading, setloading] = useState(false);
    let [pw, setPw] = useState('');
    let [confirmPW, setConfirmedPW] = useState('');
    // State to toggle visibility of password fields
    const [showpw, setShowpw] = React.useState(false);
    const [showcpw, setShowcpw] = React.useState(false)

    const toast = useToast(); // Toast for notifications
    const Navigate = useNavigate(); // Navigation hook for redirection

    const params = useLocation(); // Get current URL parameters
    const queryParams = new URLSearchParams(params.search); // Parse query string
    const email = decodeURIComponent(queryParams.get('email')); // Decode email parameter
    const path = params.pathname.trim().split("/"); // Extract parts of the path

    // Handle form submission
    const handelSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
         
        setloading(true); // Set loading state to true
        // Validate password length
        if (pw.length < 8 || confirmPW.length < 8) {
            toast({
                title: 'Password must be at least 8 characters.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            setloading(false);
            return;
        }
        // Check if passwords match
        if (pw !== confirmPW) {
            toast({
                title: 'Invalid inputs',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            setloading(false);
            return;
        };
        
        // Call the ChangeUserPW API with the path parameter, email, and new password
        await ChangeUserPW(path[2], email, confirmPW)
            .then(res => {
                setloading(false);
                // Handle success response
                if (res.status === 200) {
                    toast({
                        title: res.data.status,
                        status: 'success',
                        isClosable: true,
                        position: 'top'
                    });
                    Navigate('/auth/sign-in'); // Redirect to sign-in page
                } else {
                    // Handle failure response
                    toast({
                        title: 'Password reset failed.',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                }
            });
    }

    return (
        <Flex 
            bgGradient='linear(to-l, #26B0B1, #248F8F)' 
            w='100%' 
            pl={{ base: '0', md: '0', lg: '55px' }} 
            h='100vh' 
            justifyContent='center' 
            alignItems='center' 
            gap='30px'>
            
            <Box 
                w={{ base: '100%', md: '30%' }} 
                bg='#FFFF' 
                p='20px' 
                pt='50px' 
                h='fit-content' 
                color='gray' 
                fontSize='s' 
                mt={{ base: '0', md: '0', lg: '30px' }} 
                borderRadius='10px' 
                boxShadow='md'>
                
                {/* Logo Section */}
                <Flex m='auto' direction='column' w='50%' mb='70px'>
                    <Image src={Logo} alt='logo' />
                </Flex>

                <Box pb='50px'>
                    <Stack spacing='32px'>
                        <form onSubmit={handelSubmit}>
                            {/* Password input fields */}
                            <FormControl isRequired>
                                {/* New Password */}
                                <FormLabel as='samp'>New Password</FormLabel>
                                <InputGroup size='md' mb='10px'>
                                    <Input
                                        isRequired={true}
                                        variant='auth'
                                        h='50px'
                                        name='username'
                                        bg='#F1F6F9'
                                        placeholder='Enter New Password'
                                        borderRadius='3px'
                                        border='2px solid white'
                                        color='black'
                                        onChange={(e) => setPw(e.target.value)}
                                        type={showpw ? 'text' : 'password'}
                                    />
                                    <InputRightElement display='flex' alignItems='center' m='auto'>
                                        <Icon
                                            as={!showpw ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                            onClick={() => setShowpw(!showpw)}
                                        />
                                    </InputRightElement>
                                </InputGroup>

                                {/* Confirm New Password */}
                                <FormLabel as='samp'>Confirm New Password</FormLabel>
                                <InputGroup size='md' mb='20px'>
                                    <Input
                                        isRequired={true}
                                        variant='auth'
                                        h='50px'
                                        name='username'
                                        bg='#F1F6F9'
                                        placeholder='Confirm New Password'
                                        borderRadius='3px'
                                        border='2px solid white'
                                        color='black'
                                        onChange={(e) => setConfirmedPW(e.target.value)}
                                        type={showcpw ? 'text' : 'password'}
                                    />
                                    <InputRightElement display='flex' alignItems='center' m='auto'>
                                        <Icon
                                            as={!showcpw ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                            onClick={() => setShowcpw(!showcpw)}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            {/* Submit Button */}
                            <Button
                                isLoading={loading}
                                h='50px'
                                colorScheme='teal'
                                w='100%' 
                                borderRadius='5px'
                                fontWeight='bold'
                                border='2px solid white'
                                type='submit'
                                isDisabled={loading}>
                                Submit
                            </Button>
                        </form>
                    </Stack>
                </Box>
            </Box>
        </Flex>
    )
}

export default Changepw;
