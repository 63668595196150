import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import BreadCrumb from 'components/navbar/Breadcrumb';
import ReportTable from '../../reports/reporttable_componunt/GeneralDataTable';
import { fetch_Camp_Not_Plan, ExportExcel_Camp_Not_Plan } from '../../reports/request';


function toTitleCase(str) {
  if (!str || typeof str !== 'string' || str.trim() === '') {
    return '-';
  }
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

const CampNotPlannedReport = () => {


  // data table column 
  const column = [

    {
      name: <Text as='b' textAlign='left'>Employee Name</Text>,
      selector: row => toTitleCase(row?.name) || '-',
      sortable: true,
      sortField: 'name',
      minWidth: '80px',
    },
    {
      name: <Text as='b' textAlign='left'>Employee Code</Text>,
      selector: row => row?.emp_code || '-',
      sortable: true,
      sortField: 'emp_code',
      minWidth: '150px',
    },
    {
      name: <Text as='b' textAlign='left'>Designation</Text>,
      selector: row => row?.designation || '-',
      sortable: true,
      sortField: 'designation',
    },
    {
      name: <Text as='b'>HQ</Text>,
      selector: row => toTitleCase(row?.hq) || '-',
      sortable: true,
      sortField: 'hq',

    },
    {
      name: <Text as='b'>Region</Text>,
      selector: row => toTitleCase(row?.region) || '-',
      sortable: true,
      sortField: 'region',

    },
    {
      name: <Text as='b'>Zone</Text>,
      selector: row => toTitleCase(row?.zone) || '-',
      sortable: true,
      sortField: 'zone',

    }
  ];


  return (
    <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>
      <Box display={{ base: 'block', md: 'none' }} p='5px'>
        <BreadCrumb />
      </Box>

      {/*sending props report data table  */}
      <ReportTable
        header={'Camp Not Planned Report'}
        column={column}
        fetchfun={fetch_Camp_Not_Plan} //fetch function 
        exportFunction={ExportExcel_Camp_Not_Plan} //export function
        // exportConsent={CampReportExportConsent}
        countlabel={'Camps'}
        sortablecolm='id'
        showscale={false}
      />


    </Box>
  )
}


export default CampNotPlannedReport 