import axios from "axios";

const DATA_API = process.env.REACT_APP_API_URL;

// All reports-Api is here  --------------------------------------------------------------------------------

export const fetch_campreports = async (divi, start, end, page, text, scale, sortColumn, sortDirection) => {
    try {
        let { data } = await axios.post(`${DATA_API}/camp_report/${divi}?page=${page}&search=${text}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale,
                "column": sortColumn,
                "direction": sortDirection,
            }, {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })

        return data
    } catch (err) {
        return err
    }
};


export const fetch_TestReports = async (divi, start, end, page, text, scale, sortColumn, sortDirection) => {

    if (scale === 'all') {
        return
    }

    try {
        let { data } = await axios.post(`${DATA_API}/test_report/${divi}?page=${page}&search=${text}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale,
                "column": sortColumn,
                "direction": sortDirection,
            }, {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        });

        return data
    } catch (err) {
        return err
    }
}

export const Fetch_regionwiseReports = async (divi, start, end, page, text, scale, sortColumn, sortDirection) => {

    try {
        let { data } = await axios.get(`${DATA_API}/regionwise_camp_report/${divi}?page=${page}&from_date=${start}&to_date=${end}&search=${text}&column=${sortColumn}&direction=${sortDirection}`,
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
            });
        return data
    } catch (err) {
        return err
    }
}


export const ExportExcel_Report_Test = async (id, start, end, scale) => {

    try {
   let data =  await axios.post(`${DATA_API}/test_report_export/${id}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {

                if (response.data.type === 'application/json') {
                 
                    let temp = response.data.text().then(text => {
                         try {
                           const jsonData = JSON.parse(text);
                           // console.log(jsonData); 
                           return jsonData 
                         } catch (e) {
                           console.log(text);
                         }
                       });
                 return temp
               };

                const outputFilename = `test_reports${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
            });

        return data
    } catch (err) {
        return err
    }
};

export const ExportExcel_Report_Camp = async (id, start, end, scale) => {

    try {
     let data = await axios.post(`${DATA_API}/camp_report_export/${id}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {
                if (response.data.type === 'application/json') {
                 
                     let temp = response.data.text().then(text => {
                          try {
                            const jsonData = JSON.parse(text);
                            // console.log(jsonData); 
                            return jsonData 
                          } catch (e) {
                            console.log(text);
                          }
                        });
                  return temp
                };

                const outputFilename = `camp_report${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();

            });
           return data
    } catch (err) {
        return err
    }
};


export const CampReportExportConsent = async (id, start, end, scale) => {

    try {
       let data = await axios.post(`${DATA_API}/camp_report_export_consent/${id}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {


                if (response.data.type === 'application/json') {
                 
                    let temp = response.data.text().then(text => {
                         try {
                           const jsonData = JSON.parse(text);
                           // console.log(jsonData); 
                           return jsonData 
                         } catch (e) {
                           console.log(text);
                         }
                       });
                 return temp
               };

                const outputFilename = `camp_report_w_consent${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();

            });
            return data
    } catch (err) {
        return err
    }
}



export const ExportExcel_ReginReport = async (id, start, end, scale) => {

    try {
        await axios.get(`${DATA_API}/regionwise_camp_report_export/${id}?from_date=${start}&to_date=${end}`,
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {
                const outputFilename = `regionwise_camp_report${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();

            });
    } catch (err) {
        return err
    }
};


export const TestReportExportConsent = async (id, start, end, scale) => {

    try {
    let data = await axios.post(`${DATA_API}/test_report_export_consent/${id}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {

                if (response.data.type === 'application/json') {
                 
                    let temp = response.data.text().then(text => {
                         try {
                           const jsonData = JSON.parse(text);
                           // console.log(jsonData); 
                           return jsonData 
                         } catch (e) {
                           console.log(text);
                         }
                       });
                 return temp
               };

                const outputFilename = `test_reports_w_consent${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();

            });
            return data
    } catch (err) {
        return err
    }
};



export const Fetch_for_Scale = async (id) => {
 
    try {

        let { data } = await axios.get(`${DATA_API}/division_detail/${id}`,
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' }
            })
        // console.log({data})
        return data
    } catch (err) {
        return err
    }
};


export const Fetch_DoctorList = async (page, id, text, start, end, sortColumn, sortDirection) => {

    try {

        let { data } = await axios.post(`${DATA_API}/doctor_list/${id}?page=${page}`,
            {
                "search": text,
                "from_date": start,
                "to_date": end,
                "column": sortColumn,
                "direction": sortDirection,
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' }
            })
        return data
    } catch (err) {
        return err
    }
};


export const fetch_HQ_wise_reports = async (divi, start, end, page, text, scale, sortColumn, sortDirection) => {

    console.log({ text })
    try {
        // let { data } = await axios.get(`${DATA_API}/hqwise_camp_report/${divi}?page=${page}&from_date=${start}&to_date=${end}&search=${text}&column=${sortColumn}&direction=${sortDirection}`,
        let { data } = await axios.get(`${DATA_API}/hqwise_camp_report/${divi}?page=${page}&from_date=${start}&to_date=${end}&search=${text}&column=${sortColumn}&direction=${sortDirection}`,
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
            });
        return data
    } catch (err) {
        return err
    }
};


export const ExportExcel_HQreport = async (id, start, end, scale) => {

    try {
        await axios.get(`${DATA_API}/hqwise_camp_report_export/${id}?from_date=${start}&to_date=${end}`,
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {
                const outputFilename = `hqwise_camp_report${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();

            });
    } catch (err) {
        return err
    }
};


export const fetch_Speciality_wise_reports = async (divi, start, end, page, text, scale, sortColumn, sortDirection) => {

    console.log({ text })
    try {
        // let { data } = await axios.get(`${DATA_API}/hqwise_camp_report/${divi}?page=${page}&from_date=${start}&to_date=${end}&search=${text}&column=${sortColumn}&direction=${sortDirection}`,
        let { data } = await axios.get(`${DATA_API}/specialitywise_camp_report/${divi}?page=${page}&from_date=${start}&to_date=${end}&search=${text}&column=${sortColumn}&direction=${sortDirection}`,
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
            });
        return data
    } catch (err) {
        return err
    }
};

export const fetch_Camp_Plan =  async (divi, start, end, page, text, scale, sortColumn, sortDirection) => {
    try {
        let { data } = await axios.post(`${DATA_API}/camp_plan_report/${divi}?page=${page}&search=${text}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale,
                "column": sortColumn,
                "direction": sortDirection,
            }, {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })

        return data
    } catch (err) {
        return err
    }
};

export const ExportExcel_Camp_Plan = async (id, start, end, scale) => {

    try {
   let data =  await axios.post(`${DATA_API}/camp_plan_export/${id}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {

                if (response.data.type === 'application/json') {
                 
                    let temp = response.data.text().then(text => {
                         try {
                           const jsonData = JSON.parse(text);
                           // console.log(jsonData); 
                           return jsonData 
                         } catch (e) {
                           console.log(text);
                         }
                       });
                 return temp
               };

                const outputFilename = `camp_planed_${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
            });

        return data
    } catch (err) {
        return err
    }
};

export const fetch_camp_not_executed_report =  async (divi, start, end, page, text, scale, sortColumn, sortDirection) => {
    try {
        let { data } = await axios.post(`${DATA_API}/camp_not_executed_report/${divi}?page=${page}&search=${text}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale,
                "column": sortColumn,
                "direction": sortDirection,
            }, {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })

        return data
    } catch (err) {
        return err
    }
};

export const ExportExcel_Camp_Plan_NotExecuted = async (id, start, end, scale) => {

    try {
   let data =  await axios.post(`${DATA_API}/camp_not_executed_export/${id}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {

                if (response.data.type === 'application/json') {
                 
                    let temp = response.data.text().then(text => {
                         try {
                           const jsonData = JSON.parse(text);
                           // console.log(jsonData); 
                           return jsonData 
                         } catch (e) {
                           console.log(text);
                         }
                       });
                 return temp
               };

                const outputFilename = `camp_notexecuted${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
            });

        return data
    } catch (err) {
        return err
    }
};

export const fetch_Camp_Plan_Executed =  async (divi, start, end, page, text, scale, sortColumn, sortDirection) => {
    try {
        let { data } = await axios.post(`${DATA_API}/camp_executed_report/${divi}?page=${page}&search=${text}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale,
                "column": sortColumn,
                "direction": sortDirection,
            }, {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })

        return data
    } catch (err) {
        return err
    }
};

export const ExportExcel_Camp_Plan_Executed = async (id, start, end, scale) => {

    try {
   let data =  await axios.post(`${DATA_API}/camp_executed_export/${id}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {

                if (response.data.type === 'application/json') {
                 
                    let temp = response.data.text().then(text => {
                         try {
                           const jsonData = JSON.parse(text);
                           // console.log(jsonData); 
                           return jsonData 
                         } catch (e) {
                           console.log(text);
                         }
                       });
                 return temp
               };

                const outputFilename = `camp_executed${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
            });

        return data
    } catch (err) {
        return err
    }
};

export const ExportExcel_Camp_Not_Plan = async (id, start, end, scale) => {

    try {
   let data =  await axios.post(`${DATA_API}/camp_not_planned_export/${id}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
                responseType: 'blob'
            })
            .then((response) => {

                if (response.data.type === 'application/json') {
                 
                    let temp = response.data.text().then(text => {
                         try {
                           const jsonData = JSON.parse(text);
                           // console.log(jsonData); 
                           return jsonData 
                         } catch (e) {
                           console.log(text);
                         }
                       });
                 return temp
               };

                const outputFilename = `camp_not_planned${Date.now()}.xlsx`;
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
            });

        return data
    } catch (err) {
        return err
    }
};

export const fetch_Camp_Not_Plan =  async (divi, start, end, page, text, scale, sortColumn, sortDirection) => {
    try {
        let { data } = await axios.post(`${DATA_API}/camp_not_planned_report/${divi}?page=${page}&search=${text}`,
            {
                "from_date": start,
                "to_date": end,
                "scale_id": scale,
                "column": sortColumn,
                "direction": sortDirection,
            }, {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })

        return data
    } catch (err) {
        return err
    }
};
