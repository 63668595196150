import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    Text, Box,
    Flex, Spacer, Switch,
    Button
} from "@chakra-ui/react";
import { json, useNavigate } from 'react-router-dom';
import { BsPersonFillAdd } from "react-icons/bs";
import { EmployeeList, ExportExcel_employee, ChangeUserStatus } from 'views/admin/request/admin_request';
import Card from 'components/card/Card';
import DataTableFrame from "components/datatable/DataTableFrame";
import Datatablepagination from 'components/pagination/Datatablepagination';
import Divisionfilter from 'components/filterComponunts/Divisionfilter';
import Search from 'components/Search/Search';
import LoadingComp from 'components/LodingComp/LoadingComp';
import ExportBtn from 'components/LodingComp/ExportBtn';
import { useDebounce } from 'use-debounce';
import AlertBox from 'components/Alert/AlertBox';
import { AuthContext } from 'layouts/auth/Authantication';
import AddEmployee from './AddEmployee';
import { AddEmployeeModal } from './Modal';

// making first char to upper case
function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

const EmploysList = () => {
    let { divisions } = JSON.parse(localStorage.getItem('user')) || [];
    // let addEmpToken = JSON.parse(localStorage.getItem('userTokens'));
    let [empdata, setEmpData] = useState([]);
    let [page, setPage] = useState(1);
    let [perpage, setPerpage] = useState(1);
    let [totalData, setTotalData] = useState(0);
    let [dev_id, setDev_id] = useState(divisions[0].division_id);
    let [filterText, setFilterText] = useState('');
    let [Loading, setLoading] = useState(false);
    let [exportLoad, setExportLoad] = useState(false);
    const [sortColumn, setSortColumn] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [isOpen, setIsOpen] = useState(false);
    let [msg, setMsg] = useState({});
    const isInitialRender = useRef(true);
    const [debouncedFilterText] = useDebounce(filterText, 500);
    const { userTokens } = useContext(AuthContext);
    let [state_loading, setState_loading] = useState(false);

    // navigation hook
    const navigate = useNavigate();

    // get employee data function
    const getemployee = async (type) => {
        try {
            if (type !== 'sort') {
                setLoading(true);
            }
            let data = await EmployeeList(dev_id, debouncedFilterText, page, sortColumn, sortDirection);
            if (data.status === 'success') {
                setEmpData(data.data.data);
                setTotalData(data.data.total);
                setPerpage(data.data.per_page);
                setLoading(false);
            } else {
                navigate('/auth/sign-in');
            }
        } catch (err) {
            console.log(err);
            navigate('/auth/sign-in');
        }
    };

    // open altert box 
    const checkforToggle = async (row) => {
        setMsg({ message: `Are you shure about changing status of ${row.name}`, element: row });
        setIsOpen(true);
    };

    // change employee status function
    const handleToggle = async (item) => {
        setState_loading(true)
        let res = await ChangeUserStatus(dev_id, item.id, item.status);
        if (res.status === 'success') {
            let newdata = empdata.map((ele) => ele.id === item.id ? { ...ele, status: item.status == 1 ? 0 : 1 } : ele);
            setEmpData(newdata);
            setIsOpen(false);
        }
        setState_loading(false)
    };

    // datatable colm
    const column = [
        {
            name: <Text as='b' fontSize='13px'>Name</Text>,
            selector: row => toTitleCase(row?.name) || '-',
            sortable: true,
            sortField: 'name',
        },
        {
            name: <Text as='b' fontSize='13px'>Designation</Text>,
            selector: row => row?.designation || '-',
            sortable: true,
            sortField: 'designation',
        },
        {
            name: <Text as='b' fontSize='13px'>User Name</Text>,
            selector: row => row?.username || '-',
            sortable: true,
            sortField: 'username',
        },
        {
            name: <Text as='b' fontSize='13px'>Subscription Code</Text>,
            selector: row => row?.subscription_code || '-',
            sortable: true,
            sortField: 'subscription_code',
        },
        {
            name: <Text as='b' fontSize='13px'>Employee Code</Text>,
            selector: row => row?.emp_code || '-',
            sortable: true,
            sortField: 'emp_code',
        },
        {
            name: <Text as='b' fontSize='13px'>Status</Text>,
            selector: row => <Switch size='md' isChecked={row.status === 1} onChange={() => checkforToggle(row)} colorScheme="teal" />,
            sortable: false,
            sortField: 'status',
        },
        {
            name: <Text as='b' fontSize='13px'>Last Login At</Text>,
            selector: row => row?.last_login_At || '--',
            sortable: true,
            sortField: 'last_login_At',
        }
    ];

    // filter search function
    const handelSearch = (val) => {
        setFilterText(val);
    };

    // filter pagination
    const handelpagechange = (val) => {
        setPage(val);
    };

    // filter division
    const handelSelect = (val) => {
        setDev_id(val);
    };

    // export excel file
    const handelExport = async () => {
        try {
            setExportLoad(true);
            await ExportExcel_employee(dev_id);
            setExportLoad(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getemployee('regular');
    }, [dev_id, debouncedFilterText, page,]);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            // setSortloading(true);
            getemployee('sort');
            // setSortloading(false);
        }
    }, [sortDirection]);


    // sorting colm function
    const handleSort = (column, sortDirection) => {
        setSortColumn(column.sortField);
        setSortDirection(sortDirection);
    };

    return (
        <Card>
            <Flex gap='10px' w='100%' overflow={{ base: 'scroll', md: 'auto' }}>
                <Text fontSize='xl'>Employee List</Text>
                {/* filter by division-ID */}
                <Divisionfilter
                    Data={divisions}
                    name="Division Name"
                    handelSelect={handelSelect}
                    All={true}
                />

                {/* ----- Add Employee Feature ----------------------------------------------------------------- */}
                {/* <AddEmployeeModal /> */}

                <Spacer />
                <Flex gap='10px'>

                    {
                        userTokens === true ? <Button leftIcon={<BsPersonFillAdd />} colorScheme='green' variant='solid' rounded='sm' onClick={() => navigate('/admin/employee-list/add_employee')} >
                            Add
                        </Button> : null
                    }

                    {/* search filter */}
                    <Search filterText={filterText} handelSearch={handelSearch} />

                    {/* export excel file */}
                    <ExportBtn handelExport={handelExport} exportLoad={exportLoad} />
                </Flex>
            </Flex>

            <Box>
                {/* data table  conditional rendaring loaging or datatable*/}
                {Loading ? <LoadingComp /> :
                    <DataTableFrame
                        data={empdata}
                        keyvalue='id'
                        column={column}
                        onSort={handleSort}
                        sortServer
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        progressPending={Loading}
                    />
                }
            </Box>

            {/* data table pagination */}
            <Datatablepagination
                page={page}
                totalData={totalData}
                perpage={perpage}
                handelpagechange={handelpagechange}
                Loading={Loading} countlabel={'Employees'} />

            <AlertBox header={'Update Status'} isOpen={isOpen} setIsOpen={setIsOpen} msg={msg} action={handleToggle}  state_loading={state_loading}/>
        </Card>
    );
};

export default EmploysList;
