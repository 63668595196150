import React from 'react';
import { Box } from '@chakra-ui/react';
import EmploysList from './components/Employslist';
import BreadCrumb from 'components/navbar/Breadcrumb';

const Employee = () => {
  return (
    <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>

      <Box display={{ base: 'block', md: 'none' }} p='5px'>
        <BreadCrumb />
      </Box>
      {/* employee list table  */}
      <EmploysList />
    </Box>
  )
}

export default Employee